<template>
	<div class="grid dashboard">

		<div class="col-12 section-to-print">

			<div class="grid">
				<div class="col-12">
					<div class="card">
						<div class="grid">
							<div class="col-4" id="tarihfiltresi">
								<div class="field p-fluid">
									<label for="servisTalepTarihi">Tarih Filtresi</label>
									<Calendar showButtonBar id="servisTalepTarihi" v-model="dates" selectionMode="range" :manualInput="false" :showIcon="true" @date-select="onDateSelect" />
								</div>
							</div>
							<div class="col-6" id="tarih" style="visibility: hidden;">
								<div class="field p-fluid">
									<h4>
										Seçili Tarih <br>
										{{ formatDate(dates[0]) }} - {{ formatDate(dates[1]) }}
									</h4>
								</div>
							</div>
							<div class="col-2" id="tarihfiltresi">
								<div class="field p-fluid">
									<Button label="Yazdırma Modu" icon="pi pi-print" class="p-button p-button-info mt-5" @click="GotoReport" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="grid"  style="break-after: page;">
				<div class="col-6 lg:col-6">
					<div class="card height-100">
						<div class="card-header">
							<h5>Personellere göre Marka bazında AÇIK Satış Projeleri</h5>
						</div>
						<vuecharts id="charts1" :option="chartOption_PersonelMarkaSatislari" style="height: 500px" ref="chart" :initCfg="dd_init" />
					</div>
				</div>
				
				<div class="col-6 lg:col-6">
					<div class="card height-100">
						<div class="card-header">
							<h5>Bölgelere göre Marka bazında AÇIK Satış Projeleri</h5>
						</div>
						<vuecharts id="charts3" :option="chartOption_BolgeMarkaSatislari" style="height: 500px" ref="chart" />
					</div>
				</div>
			</div>


			<div class="grid" id="bolum2">
				<div class="col-12">
					<div class="grid">
						<div class="col-6 lg:col-6">
							<div class="card height-100">
								<div class="card-header">
									<h5>Personellere göre Marka bazında KAZANILAN Satış Projeleri</h5>
								</div>
								<vuecharts id="charts4" :option="chartOption_PersonelMarkaSatislariKazanilan" style="height: 500px" ref="chart" />
							</div>
						</div>

						<div class="col-6 lg:col-6">
							<div class="card height-100">
								<div class="card-header">
									<h5>Bölgelere göre Marka bazında KAZANILAN Satış Projeleri</h5>
								</div>
								<vuecharts id="charts5" :option="chartOption_BolgeMarkaSatislariKazanilan" style="height: 500px" ref="chart" />
							</div>
						</div>
					</div>
				</div>

			</div>

			<div class="grid" id="bolum3">
				<div class="col-12">
					<div class="grid">
						<div class="col-6 lg:col-6">
							<div class="card height-100">
								<div class="card-header">
									<h5>Personellere göre Marka bazında KAYBEDİLEN Satış Projeleri</h5>
								</div>
								<vuecharts id="charts6" :option="chartOption_PersonelMarkaSatislariKaybedilen" style="height: 500px" ref="chart" />
							</div>
						</div>

						<div class="col-6 lg:col-6">
							<div class="card height-100">
								<div class="card-header">
									<h5>Bölgelere göre Marka bazında KAYBEDİLEN Satış Projeleri</h5>
								</div>
								<vuecharts :option="chartOption_BolgeMarkaSatislariKaybedilen" style="height: 500px" ref="chart" />
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
	
</template>
	
<script>
	import CrmService from "../service/CrmService";
	//import user from '../store/user';

	export default {
		data() {
			return {
				gecmisHaftaNumaralari: [],
				seciliHaftaSayisi: 1,
				dates: [],

				crmService: null,
				chartOption_PersonelMarkaSatislari: {},
				chartOption_BolgeMarkaSatislari: {},

				chartOption_PersonelMarkaSatislariKazanilan: {},
				chartOption_BolgeMarkaSatislariKazanilan: {},

				chartOption_PersonelMarkaSatislariKaybedilen: {},
				chartOption_BolgeMarkaSatislariKaybedilen: {},

				dd_init: {renderer: 'svg'}
			}
		},
		async created() {
			/*
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Servis Panosu');
				if (yetkisiVarmi == false) {
					this.$router.replace({ name: 'accessdenied' });
				}
			}
			*/
			this.crmService = new CrmService();

			console.log('created');

			const today = new Date();
			const monday = new Date(today.setDate(today.getDate() - today.getDay() + 1));
			this.dates.push(monday);
			this.dates.push(new Date());
			

			this.OnLoad();
		},
		computed: {
			profileData(){
				return this.$store.getters.getProfile;
			}
		},
		methods: {
			async OnLoad(){
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});

				try {
					debugger;
					if (this.dates.length == 2 && this.dates[0] != null && this.dates[1] != null) {
						let data = await this.crmService.getPersonelMarkaAdetleri(this.dates);
						if (data.chartJson.length > 0) {
							this.chartOption_PersonelMarkaSatislari = JSON.parse(data.chartJson);
						}

						let data2 = await this.crmService.getBolgeMarkaAdetleri(this.dates);
						if (data2.chartJson.length > 0) {
							this.chartOption_BolgeMarkaSatislari = JSON.parse(data2.chartJson);
						}

						let dataKazanilan = await this.crmService.getPersonelMarkaAdetleriKazanilan(this.dates);
						if (dataKazanilan.chartJson.length > 0) {
							this.chartOption_PersonelMarkaSatislariKazanilan = JSON.parse(dataKazanilan.chartJson);
						}

						let data2Kazanilan = await this.crmService.getBolgeMarkaAdetleriKazanilan(this.dates);
						if (data2Kazanilan.chartJson.length > 0) {
							this.chartOption_BolgeMarkaSatislariKazanilan = JSON.parse(data2Kazanilan.chartJson);
						}

						let dataKaybedilen = await this.crmService.getPersonelMarkaAdetleriKaybedilen(this.dates);
						if (dataKaybedilen.chartJson.length > 0) {
							this.chartOption_PersonelMarkaSatislariKaybedilen = JSON.parse(dataKaybedilen.chartJson);
						}

						let data2Kaybedilen = await this.crmService.getBolgeMarkaAdetleriKaybedilen(this.dates);
						if (data2Kaybedilen.chartJson.length > 0) {
							this.chartOption_BolgeMarkaSatislariKaybedilen = JSON.parse(data2Kaybedilen.chartJson);
						}
					}
					
				} catch (error) {
					console.log(error);
				}
				finally {
					loader.hide();
				}
			},
			onDateSelect(){
				this.OnLoad();
			},
			GotoReport(){
				const dateStr1 = this.formatDateForReport(this.dates[0]);
				const dateStr2 = this.formatDateForReport(this.dates[1]);

				if (dateStr1 != "" && dateStr2 != "") {
					const reportUrl = process.env.VUE_APP_WEBAPP_URL + "/SSRS_ReportViewer.aspx?name=YonetimDashboard&date1=" + dateStr1 + "&date2=" + dateStr2;
					window.open(reportUrl, 'asd', '_blank');
				}
				else {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: 'Lütfen tarih filtresini doldurunuz', life: 3500 });
				}
				
			},
			formatDate(date){
				if (date) {
					return date.getDate() + '.' + (parseInt(date.getMonth()) + 1) + '.' + date.getFullYear();
				}
				else {
					return "";
				}
			},
			formatDateForReport(date){
				if (date) {
					return date.getFullYear() + '-' + (parseInt(date.getMonth()) + 1) + '-' + date.getDate();
				}
				else {
					return "";
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

	@media print {

		body * {
			visibility: hidden;
		}

		.section-to-print, .section-to-print * {
			visibility: visible !important;
			page-break-after: always !important;
		}

		.section-to-print {
			page-break-after: always !important;
			width: 100%;
			float: none !important;
			position: absolute !important;
			top: 0;
			left: 0;
			margin: 0 !important;
			padding: 10px !important;
			border: none !important;
		}

		::v-deep(.col-6) {
			width: 100% !important;
			page-break-after: always;
		}

		::v-deep(#bolum2) {
			margin-top: 130px;
		}

		::v-deep(#bolum3) {
			margin-top: 130px;
		}

		::v-deep(#tarih) {
			visibility: visible;
		}

		#tarihfiltresi {
			visibility: hidden !important;
			display: none !important;
		}

		::v-deep(#charts1 *) {
			visibility: visible !important;
			position: static !important;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
		}

		::v-deep(#charts2 *) {
			visibility: visible !important;
			position: static !important;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
		}

		::v-deep(#charts3 *) {
			visibility: visible !important;
			position: static !important;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
		}

		::v-deep(#charts4 *) {
			visibility: visible !important;
			position: static !important;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
		}

		::v-deep(#charts5 *) {
			visibility: visible !important;
			position: static !important;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
		}

		::v-deep(#charts6 *) {
			visibility: visible !important;
			position: static !important;
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
		}
	}
</style>	